import AOS from 'aos';
import Typed from 'typed.js';
import Splide from '@splidejs/splide';

document.addEventListener('DOMContentLoaded', () => {
  console.log('loadedddd');
  initializeAOS();
  initializeTyped();
  initializeSplide();
});

function initializeAOS() {
  AOS.init();
}

function initializeTyped() {
  let options = {
    strings: [
      'Filarmonica',
      'Piriapolis',
      'Barrio Olimpico',
      'Las Delicias',
      'Barriga Negra',
      'Centro',
    ],
    typeSpeed: 60,
    backSpeed: 40,
    attr: 'placeholder',
    loop: true,
  };

  new Typed('#lugar', options);
}

function initializeSplide() {
  let options = {
    type: 'loop',
    autoplay: true,
    interval: 8000,
    speed: 1000,
  };

  new Splide('.carousel', options).mount();
}
